import {Header} from "../header/header";
import {Content} from "../content/content";
import React, {useEffect, useState} from "react";
import {slide as Menu} from "react-burger-menu";
import {
    Button,
    Checkbox,
    CheckboxGroup,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    useDisclosure
} from "@nextui-org/react";
import axios from "axios";
import {api} from "../../utils/constants";
import {useParams} from "react-router-dom";

export const HomePage = () => {

    const params = useParams();
    const [inputSearch, setInputSearch] = useState("");
    const [sendSearch, setSendSearch] = useState(false);
    const [burger_class, setBurgerClass] = useState("burger-bar unclicked")
    const [menu_class, setMenuClass] = useState("menu hidden")
    const [isMenuClicked, setIsMenuClicked] = useState(false)
    const [selectedSubjects, setSelectedSubjects] = useState([params.id])
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    const updateMenu = () => {
        if(!isMenuClicked) {
            setBurgerClass("burger-bar clicked")
            setMenuClass("menu visible")
        }
        else {
            setBurgerClass("burger-bar unclicked")
            setMenuClass("menu hidden")
        }
        setIsMenuClicked(!isMenuClicked)
    }

    const [subjects, setSubjects] = useState([]);

    useEffect(() => {
        axios.get(`${api}/catalog/subjects`).then(response => {
            setSubjects(response.data);
        })
    }, []);



    return (
        <div>
            <div className="fixed lg:hidden w-full bg-white z-[10000000000] border-b">
                    <div>
                        <div className="w-full h-10 flex items-center pl-2 gap-2">
                            <div className="burger-menu" onClick={updateMenu}>
                                <div className={burger_class} ></div>
                                <div className={burger_class} ></div>
                                <div className={burger_class} ></div>
                            </div>
                            <p className="uppercase">Каталог</p>
                        </div>
                        <div className={menu_class}>
                            <div className="pt-10 pl-2 pr-2">
                                <div className="pb-2">
                                    <button onClick={() => {updateMenu(); onOpen(); }} className="z-10 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-unit-4 w-full h-unit-10 text-small gap-unit-2 rounded-xl  data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none bg-default data-[hover=true]:opacity-hover bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg" type="button"><svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.958 1h-1.93v1.973h1.93V1zm0 4.009h-1.93v1.973h1.93V5.009zm2.054 1.973V5.009h10.015v1.973H5.012zM2.958 9.018h-1.93v1.973h1.93V9.018zm2.054 1.973V9.018h10.015v1.973H5.012zm-2.054 2.036h-1.93V15h1.93v-1.973zM5.012 15v-1.973h10.015V15H5.012zm0-12.027V1h10.015v1.973H5.012z" fill="#fff"></path>
                                    </svg> Все категории</button>
                                </div>
                                <div className="flex flex-col gap-5 min-w-[250px] max-w-[250px]">
                                    <span className="relative text-medium text-foreground-500">Категории</span>
                                    {subjects.slice(0, 20).map((x,i) => {
                                        return (
                                            <Link href={x.subjectId} className="pb-2">
                                                <span className="leading-[10px]">{x.subjectName} [{x.amount}]</span>
                                            </Link>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <Modal size="full" isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalBody>
                                <div className="flex flex-wrap overflow-scroll">
                                    {subjects.reduce((acc, x, index) => {
                                        const columnIndex = Math.floor(index / 20); // Вычисляем индекс столбца
                                        if (!acc[columnIndex]) acc[columnIndex] = []; // Если столбец ещё не инициализирован, создаём его
                                        acc[columnIndex].push(x);
                                        return acc;
                                    }, []).map((column, index) => (
                                        <div key={index} className="flex gap-1 flex-col w-1/4">
                                            {column.map(x => (
                                                <Link key={x.subjectId} href={x.subjectId} className="pb-2">
                                                    <span className="text-[10px] leading-3">{x.subjectName} [{x.amount}]</span>
                                                </Link>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Закрыть
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <div className="flex flex-col items-center">
                <div className="p-5 w-full mb-10">
                    <Header isMenuClicked={isMenuClicked} size="10132" sendSearch={sendSearch} setSendSearch={setSendSearch} inputSearch={inputSearch} setInputSearch={setInputSearch}/>
                    <div className="m-[20px]"></div>
                    <Content isMenuClicked={isMenuClicked} selectedSubjects={selectedSubjects} setSelectedSubjects={setSelectedSubjects} sendSearch={sendSearch} setSendSearch={setSendSearch} inputSearch={inputSearch} setInputSearch={setInputSearch}/>
                </div>
            </div>
        </div>
    )
}
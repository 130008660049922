import {Button, Card, CardBody, CardFooter, Image, Pagination} from "@nextui-org/react";
import {volHost} from "../../utils/volHost";
import {useCallback, useMemo, useState} from "react";

export const Cards = ({isMenuClicked, data, page, setPage}) => {

    const [vissibleItems, setVisibleItems] = useState(0);

    const filteredItems = useMemo(() => {
        let element = [...data];
        return element;
    }, [data])

    const pages = Math.ceil(filteredItems.length / 12);

    const items = useMemo(() => {
        const elements = [...filteredItems];
        const start = 0;
        const end = vissibleItems + 24;
        setVisibleItems(end)
        return elements.slice(start, end);
    }, [page, filteredItems]);

    function isMobileDevice() {
        return window.matchMedia("only screen and (max-width: 760px)").matches;
    }

    const openCard = (artcode) => {
        const url = "https://www.wildberries.ru/catalog/" + artcode + "/detail.aspx?utm_campaign=index&utm_medium=web&utm_source=slibkiwb.ru";

        if (isMobileDevice()) {
            window.location.href = url;
        } else {
            // Для десктопных версий продолжаем использовать window.open для открытия новой вкладки
            window.open(url, '_blank');
        }
    }

    return (
        <div className="">
            <div className="gap-2 grid grid-cols-2 sm:grid-cols-4">
                {items.map((item, index) => {
                    return (
                            <Card shadow="sm" className={isMenuClicked ? "relative z-[-1]" : "relative z-10"} key={index} isPressable onPress={() => openCard(item.artcode)}>
                                <div className="absolute bg-alert z-20 rounded pb-[5px] pl-[10px] pr-[10px] top-[5%] right-[5%]">
                                    <span className="font-medium text-xs text-white">-{Math.floor(item.discount)}%</span>
                                </div>
                                <CardBody className="overflow-visible p-0">
                                    <Image
                                        shadow="sm"
                                        radius="lg"
                                        width="100%"
                                        alt={item.name}
                                        className="w-full object-cover h-[250px]"
                                        src={volHost(item?.artcode) + "/images/big/1.webp"}
                                    />
                                </CardBody>
                                <CardFooter className="text-small flex-col">
                                    <b>{item.name}</b>
                                    <div className="flex justify-between w-full pt-2">
                                        <div className="flex flex-col items-start">
                                            <p className="text-price font-jost font-bold text-lg leading-4">{item.price} ₽</p>
                                            <span className="text-xs font-jost font-bold text-alert">Сегодня</span>
                                        </div>
                                        <div className="flex flex-col items-end">
                                            <p className="font-jost text-base text-oldprice leading-4"><span className="line-through">{item.oldPrice}</span> ₽</p>
                                            <span className="text-subalert font-jost text-xs">Вчера</span>
                                        </div>
                                    </div>
                                    <div className="pt-2 flex gap-1 items-start w-full">
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.10955 2.46567C7.47636 1.72242 8.53621 1.72242 8.90302 2.46567L10.1248 4.94117C10.2704 5.23632 10.552 5.44088 10.8777 5.48821L13.6096 5.88518C14.4298 6.00436 14.7573 7.01234 14.1638 7.59087L12.187 9.51778C11.9513 9.74752 11.8437 10.0785 11.8994 10.4029L12.366 13.1238C12.5062 13.9407 11.6487 14.5636 10.9151 14.1779L8.47163 12.8933C8.1803 12.7402 7.83227 12.7402 7.54095 12.8933L5.09748 14.1779C4.36385 14.5636 3.50642 13.9407 3.64653 13.1238L4.11319 10.4029C4.16883 10.0785 4.06128 9.74752 3.82559 9.51778L1.84878 7.59087C1.25527 7.01233 1.58278 6.00436 2.403 5.88518L5.13488 5.48821C5.46059 5.44088 5.74215 5.23632 5.88782 4.94117L7.10955 2.46567Z" fill="#FF6633"/>
                                        </svg>
                                        <span className="font-medium text-xs">
                            {item?.rating}
                        </span>

                                    </div>
                                    <Button onPress={() => openCard(item.artcode)} className="w-full mt-2 bg-primary-400">
                                        <span className="font-bold text-white text-xs font-jost">Перейти</span>
                                    </Button>
                                </CardFooter>
                            </Card>
                    )
                })}
            </div>
            <div className="mt-5 w-full flex justify-center">
                <Button isDisabled={vissibleItems + 24 >= filteredItems.length} color="secondary" onPress={() => setPage(page + 1)} variant="bordered">
                    Показать еще
                </Button>
            </div>

        </div>
    )
}
import {
    Checkbox,
    CheckboxGroup,
    Chip, Link,
    Navbar,
    NavbarContent,
    NavbarMenu, NavbarMenuItem,
    NavbarMenuToggle
} from "@nextui-org/react";
import {useState} from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";


export const Sidebar = ({category, setSelectedSubjects, selectedSubjects}) => {

    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    return (
        <>
            <Modal size="full" isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalBody>
                                <div className="flex flex-wrap p-5">
                                    {category.reduce((acc, x, index) => {
                                        const columnIndex = Math.floor(index / 20); // Вычисляем индекс столбца
                                        if (!acc[columnIndex]) acc[columnIndex] = []; // Если столбец ещё не инициализирован, создаём его
                                        acc[columnIndex].push(x);
                                        return acc;
                                    }, []).map((column, index) => (
                                        <div key={index} className="flex gap-2 flex-col w-1/3">
                                            {column.map(x => (
                                                <Link key={x.subjectId} href={x.subjectId} className="pb-2">
                                                    <span className="leading-[20px]">{x.subjectName} [{x.amount}]</span>
                                                </Link>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Закрыть
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <div className="pb-2">
                <button onClick={onOpen} className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-unit-4 w-full h-unit-10 text-small gap-unit-2 rounded-xl  data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none bg-default data-[hover=true]:opacity-hover bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg" type="button"><svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.958 1h-1.93v1.973h1.93V1zm0 4.009h-1.93v1.973h1.93V5.009zm2.054 1.973V5.009h10.015v1.973H5.012zM2.958 9.018h-1.93v1.973h1.93V9.018zm2.054 1.973V9.018h10.015v1.973H5.012zm-2.054 2.036h-1.93V15h1.93v-1.973zM5.012 15v-1.973h10.015V15H5.012zm0-12.027V1h10.015v1.973H5.012z" fill="#fff"></path>
                </svg> Все категории</button>
            </div>
            <div className="flex flex-col gap-5 min-w-[250px] max-w-[250px]">
                <span className="relative text-medium text-foreground-500">Категории</span>
                    {category.slice(0, 20).map((x,i) => {
                        return (
                            <Link href={x.subjectId} className="pb-2">
                                <span className="leading-[10px]">{x.subjectName} [{x.amount}]</span>
                            </Link>
                        )
                    })}
            </div>
           </>
    )
}



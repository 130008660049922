import {Route, Routes} from "react-router-dom";
import {HomePage} from "./components/pages/Home";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage/>} />
      <Route path="/:id" element={<HomePage/>} />
    </Routes>
  );
}

export default App;

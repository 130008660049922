import {Sidebar} from "../sidebar/sidebar";
import {
    Button,
    Checkbox,
    Chip,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Input,
    Popover, PopoverContent, PopoverTrigger
} from "@nextui-org/react";
import {SearchIcon} from "../icons/search";
import {Cards} from "../cards/cards";
import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
import {api} from "../../utils/constants";
import {ChevronDownIcon} from "../icons/chevron_down";

export const Content = ({selectedSubjects, setSelectedSubjects, inputSearch, setInputSearch, sendSearch, setSendSearch, isMenuClicked}) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [priceDescriptor, setPriceSortDescriptor] = useState(new Set(["asc"]));
    const [discountDescriptor, setDiscountSortDescriptor] = useState(new Set(["Вначале с наибольшей скидкой"]));
    const [subjects, setSubjects] = useState([]);
    const [page, setPage] = useState(1);
    const [hide, setHide] = useState(true);

    useEffect(() => {
        setPage(1);
    }, [selectedSubjects]);

    useEffect(() => {
        setPage(1)
        let body = {
            "keyword" : inputSearch,
            "subject" : selectedSubjects != null ? selectedSubjects : [0],
            "sorting": [],
        }

        if (discountDescriptor.has("Вначале с наибольшей скидкой")) {
            body = {
                "keyword" : inputSearch,
                "sorting": [
                {
                    "sortType" : "DESC",
                    "sortBy": "DISCOUNT"
                }
            ],
            }
        }

        if (discountDescriptor.has("Вначале с наименьшей скидкой")) {
            body = {
                "keyword" : inputSearch,
                "sorting": [
                    {
                        "sortType" : "ASC",
                        "sortBy": "DISCOUNT"
                    }
                ],
            }
        }
        if (discountDescriptor.has("Вначале товары дешевле")) {
            body = {
                "keyword" : inputSearch,
                "sorting": [
                    {
                        "sortType" : "ASC",
                        "sortBy": "PRICE"
                    }
                ],
            }
        }
        if (discountDescriptor.has("Вначале товары дороже")) {
            body = {
                "keyword" : inputSearch,
                "sorting": [
                    {
                        "sortType" : "DESC",
                        "sortBy": "PRICE"
                    }
                ],
            }
        }
        if (Array.from(selectedSubjects)[0])
            body.subject = selectedSubjects
        axios.post(`${api}/catalog`, body)
            .then(response => {
                setData(response.data)
            })
            .finally(() => setLoading(false));
    }, [sendSearch, priceDescriptor, discountDescriptor, selectedSubjects]);

    const filteredItems = useMemo(() => {
        const elements = [...data];
        if (hide) {
            return data.filter(x => x.rating != "0")
        } return elements;
    }, [hide, data])

    useEffect(() => {
        axios.get(`${api}/catalog/subjects`).then(response => {
            setSubjects(response.data);
        })
    }, []);


    if(loading) return "Loading..."

    return (
        <div className="flex gap-10 justify-center">
            <div className="flex-col gap-5 hidden lg:flex">
                <div className="rounded border-1 p-5">
                    <div className="flex justify-between ">
                        <span className="relative text-medium text-foreground-500">Телеграм бот</span>
                        <a className="text-medium cursor-pointer text-primary-300" href="https://t.me/slivkiwbru" target="_blank">@slivkiwbru</a>
                    </div>
                    <p className="pt-5">
                        Уведобит вас о скидках<br/>
                        в выбранных категориях
                    </p>
                </div>
                <div className="rounded border-1 p-5 h-full">
                    <Sidebar page={page} setPage={setPage} selectedSubjects={selectedSubjects} setSelectedSubjects={setSelectedSubjects} category={subjects}/>
                </div>
            </div>
            <div className={isMenuClicked ? "rounded border-1 p-5 z-[-1] w-full relative lg:z-10" : "rounded border-1 p-5 w-full relative z-10"}>
                <div className="flex flex-wrap items-center gap-5 justify-between">
                    <div className="flex gap-5">
                        <Dropdown className="">
                            <DropdownTrigger className="flex">
                                <Button
                                    endContent={<ChevronDownIcon className="text-small" />}
                                    size="sm"
                                    variant="flat"
                                >
                                    {discountDescriptor}
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectionMode="single"
                                selectedKeys={discountDescriptor}
                                onSelectionChange={setDiscountSortDescriptor}
                            >
                                <DropdownItem key={"Вначале с наибольшей скидкой"} className="capitalize">
                                    Вначале с наибольшей скидкой
                                </DropdownItem>
                                <DropdownItem key={"Вначале с наименьшей скидкой"} className="capitalize">
                                    Вначале с наименьшей скидкой
                                </DropdownItem>
                                <DropdownItem key={"Вначале товары дешевле"} className="capitalize">
                                    Вначале товары дешевле
                                </DropdownItem>
                                <DropdownItem key={"Вначале товары дороже"} className="capitalize">
                                    Вначале товары дороже
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="flex gap-1">
                        <Checkbox defaultSelected value={hide} onValueChange={setHide} color="secondary">
                            Скрыть товары без продаж
                        </Checkbox>
                        <Popover placement="top">
                            <PopoverTrigger>
                                <Chip className="cursor-pointer">?</Chip>
                            </PopoverTrigger>
                            <PopoverContent>
                                <div className="px-1 py-2">
                                    <div className="text-small font-bold">Подсказка</div>
                                    <div className="text-tiny">Убирает из выдаче карточки содержащие нулевой рейтинг</div>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>
                <div className="mt-5">
                {/*    <p>Найдено по запросу "юбка" 22 февраля 106 из 10 045 товаров со скидкой от 25% до 92%</p>*/}
                </div>
                <div className="mt-5 relative z-[-1]">
                    <Cards isMenuClicked={isMenuClicked} page={page} setPage={setPage} data={filteredItems} inputSearch={inputSearch}/>
                </div>
            </div>
        </div>
    )
}